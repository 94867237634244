import { Navigate, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Privacy from "./pages/Privacy";
import InstitutionList from "./pages/InstitutionList";

export default function App() {
  return (
    <Routes>
      <Route path="" element={<Main />} />
      <Route path="/*" element={<Navigate to="" />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/supported-institutions" element={<InstitutionList />} />
    </Routes>
  );
}
