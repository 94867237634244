import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function InstitutionList() {
  const navigate = useNavigate();
  const backToHome = () => {
    navigate("/");
  };
  useEffect(() => {
    document.title = "BeeFriends: Supported Institutions List";
  });
  return (
    <div className="bg-yellow-500 h-screen">
      <header className="navbar bg-primary text-black">
        <div className="flex">
          <button className="flex items-center" onClick={backToHome}>
            <img
              src="./assets/logo.png"
              alt="logo"
              className="w-[64px] md:w-[96px]"
            />
            <p className="text-2xl md:text-5xl font-extrabold">BeeFriends</p>
          </button>
          <div className="divider divider-accent divider-horizontal"></div>
          <p className="font-bold text-xl md:text-3xl">Supported Institutions</p>
        </div>
      </header>
      <main className="p-6">
        <div className="card bg-white shadow-2xl max-h-[80vh] md:pr-4">
          <div className="card-body overflow-y-auto">
            <p className="card-title font-bold text-2xl">
              BeeFriends App - Supported Institutions List
            </p>
            <div className="divider divider-accent"></div>
            <p>
                This list covers all the supported institutions by the BeeFriends platform up until the last update on February 23rd, 2024.<br/><br/>
                The following institutions are currently supported: 
                <br/><i>Institution Name (Type) (Country) (Sign-in Method)</i><br/><br/>
                <ul>
                    <li>
                        <b>❖ BINUS University (University) (Indonesia) (Microsoft Online)</b>
                    </li>
                </ul>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default InstitutionList;
